:root {
  --section-rotate: 9vw;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  color: #777;
  flex-direction: column;
  min-width: 1250px;
  min-height: 100vh;
  padding: 3rem;
  font-family: Lato, sans-serif;
  font-weight: 300;
  line-height: 1.6;
  display: flex;
}

.main {
  background-color: #fff;
  flex: 1;
  padding: 4rem 6rem;
  position: relative;
}

.header__logo img {
  align-self: center;
  height: 8.5rem;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.heading-primary, .heading-tertirary {
  color: #212529ce;
  text-transform: uppercase;
  font-weight: 600;
}

.heading-primary span, .heading-tertirary span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 1rem 1.5rem;
  line-height: 1;
}

.heading-primary {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  font-size: 5rem;
}

.heading-tertirary {
  text-align: right;
  z-index: 10;
  width: 70%;
  font-size: 2.75rem;
  position: absolute;
  bottom: 1rem;
  right: 2rem;
}

.btn, .btn:link, .btn:visited {
  text-transform: uppercase;
  backface-visibility: hidden;
  cursor: pointer;
  border: none;
  border-radius: 10rem;
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .4s;
  display: inline-block;
  position: relative;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem #00000026;
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 .5rem 1rem #00000026;
}

.btn--green {
  color: #fff;
  background-color: #1864ab;
}

.btn--small {
  padding: 1rem 1.5rem !important;
  font-size: 1.4rem !important;
}

.btn--ex--small {
  padding: .3rem .8rem !important;
  font-size: 1.4rem !important;
}

.header {
  z-index: 100;
  background-color: #495057;
  justify-content: space-between;
  height: 8rem;
  padding: 0 5rem;
  display: flex;
  position: relative;
}

.footer {
  grid-row-gap: .75rem;
  background-color: #fff;
  grid-template-columns: auto auto;
  padding: 6rem 4rem 3rem;
  font-size: 1.4rem;
  display: grid;
}

.footer__logo {
  grid-row: 1;
  align-self: center;
}

.footer__logo img {
  height: 8.5rem;
}

.footer__copyright {
  color: #999;
  justify-self: end;
}

.nav__el {
  align-items: center;
  display: flex;
}

.nav__user-img {
  border-radius: 50%;
  width: 5.5rem;
  height: 5.5rem;
  margin-right: 1rem;
}

.card-container {
  grid-gap: 7rem;
  grid-template-columns: repeat(3, 1fr);
  max-width: 120rem;
  margin: 0 auto;
  display: grid;
}

.splitter, .splitterInner {
  grid-gap: 7rem;
  grid-template-columns: 1fr 100px 1fr;
  max-width: 120rem;
  margin-top: 50px;
  margin-bottom: 20px;
  display: grid;
}

.card {
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  align-self: flex-start;
  display: flex;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem #0000001a;
}

.card__header {
  position: relative;
}

.card__picture {
  clip-path: polygon(0 0, 100% 0%, 100% 83%, 0% 98%);
  height: 22rem;
  position: relative;
}

.card__picture-overlay {
  opacity: .4;
  background-image: linear-gradient(to bottom right, #f1f3f5, #dee2e6);
  width: 100%;
  height: 100%;
  position: absolute;
}

.card__picture-img {
  -o-object-fit: scale-down;
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}

.card__details {
  grid-row-gap: 1.75rem;
  grid-column-gap: 1.25rem;
  grid-template-columns: 1fr 1.25fr;
  padding: 2.5rem;
  display: grid;
}

.card__sub-heading {
  text-transform: uppercase;
  grid-column: 1 / -1;
  font-size: 1.7rem;
  font-weight: 700;
}

.card__text {
  grid-column: 1 / -1;
  margin-top: -1rem;
  margin-bottom: .75rem;
  font-size: 1.5rem;
  font-style: italic;
}

.card__data {
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
}

.card__data__num {
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
}

.card__data svg {
  margin-right: .2rem;
}

.card__icon {
  fill: #4dabf7;
  width: 2rem;
  height: 2rem;
}

.card__footer {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: #f7f7f7;
  border-top: 1px solid #f1f1f1;
  grid-template-columns: 2fr 1fr;
  margin-top: auto;
  padding: 2.5rem 3rem;
  font-size: 1.4rem;
  display: grid;
}

.card__footer-value {
  margin-right: 20px;
  font-weight: 700;
}

.card__footer-text {
  color: #000;
  font-weight: 600;
}

.card__ratings {
  grid-row: 2 / 3;
}

.card .btn-small, .card .btn {
  grid-row: 1 / 3;
  place-self: center end;
}

.splitter {
  color: #343a40;
  align-items: center;
  max-width: 120rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.4rem;
  font-weight: 700;
  display: flex;
}

.splitter:before, .splitter:after {
  content: "";
  background: #343a40;
  flex: 1;
  height: 2px;
}

.splitter:before {
  margin-right: 1.25rem;
}

.splitter:after {
  margin-left: 1.25rem;
}

.icon-img {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 10px;
}

.heading-usd {
  color: #f7f8face;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
}

/*# sourceMappingURL=index.279a2da4.css.map */
