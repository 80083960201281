:root {
  --section-rotate: 9vw;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  line-height: 1.6;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  color: #777;
  padding: 3rem;
  min-height: 100vh;
  min-width: 1250px;
  display: flex;
  flex-direction: column;
}

.main {
  background-color: #ffffff;
  padding: 4rem 6rem;
  flex: 1;
  position: relative;
}

.header__logo img {
  align-self: center;
  height: 8.5rem;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.heading-primary,
.heading-tertirary {
  color: #212529ce;
  text-transform: uppercase;
  font-weight: 600;
}

.heading-primary span,
.heading-tertirary span {
  padding: 1rem 1.5rem;
  line-height: 1;
  box-decoration-break: clone;
}

.heading-primary {
  font-size: 5rem;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.heading-tertirary {
  font-size: 2.75rem;
  text-align: right;
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  width: 70%;
  z-index: 10;
}

.btn,
.btn:link,
.btn:visited {
  font-size: 1.6rem;
  border-radius: 10rem;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
  font-weight: 400;
  backface-visibility: hidden;
  border: none;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.btn--green {
  background-color: #1864ab;
  color: #fff;
}

.btn--small {
  padding: 1rem 1.5rem !important;
  font-size: 1.4rem !important;
}
.btn--ex--small {
  padding: 0.3rem 0.8rem !important;
  font-size: 1.4rem !important;
}
.header {
  background-color: #495057;
  padding: 0 5rem;
  height: 8rem;
  position: relative;
  z-index: 100;
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-between;
}

.footer {
  background-color: #fff;
  padding: 6rem 4rem 3rem 4rem;
  font-size: 1.4rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 0.75rem;
}

.footer__logo {
  grid-row: 1;
  -ms-flex-item-align: center;
  align-self: center;
}

.footer__logo img {
  height: 8.5rem;
}

.footer__copyright {
  justify-self: end;
  color: #999;
}

.nav__el {
  display: flex;
  align-items: center;
}
.nav__user-img {
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.card-container {
  max-width: 120rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 7rem;
}

.splitter {
  max-width: 120rem;
  margin-top: 50px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
  grid-gap: 7rem;
}

.splitterInner {
  max-width: 120rem;
  margin-top: 50px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
  grid-gap: 7rem;
}

.card {
  border-radius: 3px;
  align-self: flex-start;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.card__header {
  position: relative;
}
.card__picture {
  position: relative;
  clip-path: polygon(0 0, 100% 0%, 100% 83%, 0% 98%);
  height: 22rem;
}
.card__picture-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right bottom, #f1f3f5, #dee2e6);
  opacity: 0.4;
}
.card__picture-img {
  -o-object-fit: scale-down;
  object-fit: scale-down;
  height: 100%;
  width: 100%;
}
.card__details {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  grid-row-gap: 1.75rem;
  grid-column-gap: 1.25rem;
  padding: 2.5rem 2.5rem;
}
.card__sub-heading {
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: 700;
  grid-column: 1 / -1;
}
.card__text {
  grid-column: 1 / -1;
  font-size: 1.5rem;
  font-style: italic;
  margin-top: -1rem;
  margin-bottom: 0.75rem;
}
.card__data {
  font-size: 1.5rem;
  display: flex;
  font-weight: 700;
  align-items: center;
}

.card__data__num {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
}

.card__data svg {
  margin-right: 0.2rem;
}
.card__icon {
  height: 2rem;
  width: 2rem;
  fill: #4dabf7;
}
.card__footer {
  background-color: #f7f7f7;
  padding: 2.5rem 3rem;
  border-top: 1px solid #f1f1f1;
  font-size: 1.4rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin-top: auto;
}
.card__footer-value {
  font-weight: 700;
  margin-right: 20px;
}
.card__footer-text {
  font-weight: 600;
  color: #000;
}
.card__ratings {
  grid-row: 2 / 3;
}
.card .btn-small,
.card .btn {
  grid-row: 1 / 3;
  justify-self: end;
  align-self: center;
}

.splitter {
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 2.4rem;
  color: #343a40;
}

.splitter::before,
.splitter::after {
  content: "";
  flex: 1;
  height: 2px;
  background: #343a40;
}

.splitter::before {
  margin-right: 1.25rem;
}

.splitter::after {
  margin-left: 1.25rem;
}

.icon-img {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 10px;
}

.heading-usd {
  color: #f7f8face;
  font-weight: 500;
  font-size: 3rem;
  text-align: center;
}
